import { t } from 'i18next';
import { Suspense, lazy } from 'react';
import {
    Navigate,
    Route,
    Routes,
    useLocation,
    useMatch,
} from 'react-router-dom';
import { getLocalContextFromSearchParams } from 'services/urlParams';
import { useWrongAccountWarning } from 'hooks/users';
import { useApplicationContextState } from 'components/ApplicationContext/hooks';
import FullScreenLoading from 'components/FullScreenFetching';
import { AppNotification } from 'components/Notification';
import { parseEntity } from 'services/microsoftTeamsHelpers';

import ApplicationContextProvider from '../components/ApplicationContext';
import Layout from '../layout';
import InternalError from '../pages/InternalError';
import SignInWithMicrosoft from '../pages/SignInMicrosoftPage';
import SignInWithMicrosoftEnd from '../pages/SignInMicrosoftPageEnd';
import SignInMicrosoftPageFromTeams from '../pages/SignInMicrosoftPageFromTeams';
import SignInPage from '../pages/SignInPage';
import ConfigPage from '../pages/ConfigPage';
import OneTimeCodePage from '../pages/OneTimeCodePage';
import { getLastVisitedChannel } from '../services/routerHelper';
import {
    getMsTeamsContextFromLocalStorage,
    areWeRunningInsideMsTeamsPersonalTabOrChannelTab,
} from 'services/localStorage';
import { usePageContextFromParams } from 'hooks/url';

function reloadWindow(e) {
    console.error(e);
    window?.location.reload();
}

const AppPage = lazy(() => import('../pages/AppPage').catch(reloadWindow));
const RemovePage = lazy(() =>
    import('../pages/RemovePage').catch(reloadWindow),
);
const DocumentView = lazy(() =>
    import('components/DocumentView').catch(reloadWindow),
);
const AnalyticsView = lazy(() =>
    import('components/AnalyticsView').catch(reloadWindow),
);
const UserBlockedPage = lazy(() =>
    import('../pages/UserBlockedPage').catch(reloadWindow),
);

const CreateChannelPage = lazy(() =>
    import('../pages/CreateChannelPage').catch(reloadWindow),
);

const TrialExpiredPage = lazy(() =>
    import('../pages/TrialExpiredPage').catch(reloadWindow),
);

function ReaderViewSwitcher({ children }) {
    const { getUser, getOrganization } = useApplicationContextState();
    const { id: organizationId } = getOrganization();

    const user = getUser();
    const { channelId, pageId: currentPageId } = usePageContextFromParams();

    const readerViewUrl = `https://read.perfectwiki.xyz/w/${organizationId}/${channelId}/${
        currentPageId ? currentPageId : ''
    }`;
    // Show reader view for all readers
    const shouldShowReaderView =
        user.isReader || user.preferences?.defaultView === 'reader_view';

    if (shouldShowReaderView) {
        window.location.replace(readerViewUrl);
        return (
            <FullScreenLoading
                message={t(
                    'Routes.ReaderViewLoading',
                    'Loading Reader View...',
                )}
            />
        );
    }

    return children;
}

function AccessToChannelRequired({ children }) {
    const { getUser, getAccessibleChannels, getOrganization } =
        useApplicationContextState();

    const teamsContext = getMsTeamsContextFromLocalStorage();
    const { id: orgId } = getOrganization();
    const { isMsTeams, isMsTeamsTab } =
        areWeRunningInsideMsTeamsPersonalTabOrChannelTab();
    const { showWarning } = useWrongAccountWarning();
    const channels = getAccessibleChannels(true);
    const { isBlocked } = getUser();
    const location = useLocation();
    const match = useMatch('/:teamId/:channelId/*', location.pathname);

    if (isBlocked) {
        return <Navigate to='/blocked' />;
    }

    function getMsTeamsLinkContext(teamsContext) {
        if (!teamsContext?.page) {
            return;
        }

        const { subPageId: pageContextFromTeams, id: teamsContextChannelId } =
            teamsContext.page;

        const entity = parseEntity(pageContextFromTeams);
        if (!entity) {
            return;
        }

        const { channelId, subPageId } = entity;

        return {
            channelId: channelId || teamsContextChannelId || orgId, // 1) channel embedded to subPageId 2) channel from teams context 3) orgId
            subPageId: subPageId || pageContextFromTeams, // 1) parsedSubPageId 2) raw pageId from Teams
        };
    }

    const { channelId } = match?.params || {};
    // If we on a MS Teams Channel Tab or Personal Tab, we override channelId and pageId by data from MS Teams Context
    if (isMsTeams && !channelId) {
        const teamsParamsPayload = getMsTeamsLinkContext(teamsContext);
        if (teamsParamsPayload) {
            const channelId = teamsParamsPayload.channelId;
            const foundChannel = channels?.find(
                (ch) => ch.channelId === channelId,
            );
            const teamId = foundChannel?.teamId || orgId;
            const subPageId = teamsParamsPayload.subPageId;

            return (
                <Navigate
                    replace
                    to={`/${teamId}/${channelId}${
                        subPageId ? `/${subPageId}` : ''
                    }`}
                />
            );
        }
    }

    const {
        teamId: queryTeamId,
        channelId: queryChannelId,
        subEntityId: querySubEntityId,
    } = getLocalContextFromSearchParams() || {};

    if (queryTeamId && queryChannelId) {
        return (
            <Navigate
                replace
                to={`/${queryTeamId}/${queryChannelId}${
                    querySubEntityId ? `/${querySubEntityId}` : ''
                }`}
            />
        );
    }

    const foundChannel = channelId
        ? channels.find((ch) => ch.channelId === channelId)
        : null;
    const visibleChannels = channels.filter((ch) => ch.state !== 'deleted');
    const originalUrl = window.location.href;

    if (!foundChannel || foundChannel?.state === 'deleted') {
        if (visibleChannels.length === 0) {
            return <Navigate replace to='/no-channels-found' />;
        }

        if (foundChannel?.state === 'deleted') {
            AppNotification.warning({
                key: 'channel-archived-warning',
                description: t(
                    'Layout.KbArchived',
                    'Knowledge base or page that you requested is archived. We redirected you to a default page.',
                ),
                duration: 0,
            });
        }

        if (!foundChannel && channelId) {
            if (isMsTeamsTab) {
                return <Navigate replace to='/create-channel' />;
            } else {
                showWarning(originalUrl);
            }
        }

        const lastVisitedChannel = getLastVisitedChannel();
        const visitedChannel = lastVisitedChannel
            ? visibleChannels.find((ch) => ch.channelId === lastVisitedChannel)
            : null;

        const firstVisibleChannel = visibleChannels[0];
        const nextChannel = visitedChannel || firstVisibleChannel;

        return (
            <Navigate
                replace
                to={`/${nextChannel.teamId || nextChannel.organizationId}/${
                    nextChannel.channelId
                }`}
            />
        );
    }

    return <>{children}</>;
}

function AuthorizedSwitch() {
    return (
        <ApplicationContextProvider>
            <Routes>
                <Route path='/blocked' element={<UserBlockedPage />} />
                <Route path='/trial-expired' element={<TrialExpiredPage />} />
                <Route
                    path='/no-channels-found'
                    element={<CreateChannelPage isOnboarding={true} />}
                />
                <Route
                    path='/create-channel'
                    element={<CreateChannelPage isOnboarding={false} />}
                />
                <Route
                    path='/:teamId?/:channelId?/*'
                    element={
                        <Suspense
                            fallback={
                                <FullScreenLoading
                                    message={t(
                                        'fetching-layout',
                                        'Fetching layout...',
                                    )}
                                />
                            }>
                            <AccessToChannelRequired>
                                <ReaderViewSwitcher>
                                    <AppPage />
                                </ReaderViewSwitcher>
                            </AccessToChannelRequired>
                        </Suspense>
                    }>
                    <Route
                        index
                        element={
                            <Suspense
                                fallback={
                                    <FullScreenLoading
                                        message={t(
                                            'fetching-analytics',
                                            'Fetching analytics...',
                                        )}
                                    />
                                }>
                                <AnalyticsView />
                            </Suspense>
                        }
                    />
                    <Route
                        path=':subEntityId'
                        element={
                            <Suspense
                                fallback={
                                    <FullScreenLoading
                                        message={t(
                                            'fetching-document',
                                            'Fetching document...',
                                        )}
                                    />
                                }>
                                <DocumentView />
                            </Suspense>
                        }
                    />
                </Route>
            </Routes>
        </ApplicationContextProvider>
    );
}

export default function Router() {
    return (
        <Layout>
            <Routes>
                <Route path='/signin' element={<SignInPage />} />
                <Route path='/signin/code' element={<OneTimeCodePage />} />
                <Route
                    path='/signin/microsoft/teams/auth-start'
                    element={<SignInMicrosoftPageFromTeams />}
                />
                <Route
                    path='/signin/microsoft/auth-end'
                    element={<SignInWithMicrosoftEnd />}
                />
                <Route
                    path='/signin/microsoft/*'
                    element={<SignInWithMicrosoft />}
                />
                <Route path='/error' element={<InternalError />} />
                <Route path='/config' element={<ConfigPage />} />
                <Route
                    path='/remove'
                    element={
                        <Suspense
                            fallback={
                                <FullScreenLoading
                                    message={t(
                                        'fetching-page',
                                        'Fetching page...',
                                    )}
                                />
                            }>
                            <RemovePage />
                        </Suspense>
                    }
                />
                <Route path='*' element={<AuthorizedSwitch />} />
            </Routes>
        </Layout>
    );
}
