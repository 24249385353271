import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import HttpBackend from 'i18next-http-backend';
import { locizePlugin } from 'locize';
import { initReactI18next } from 'react-i18next';

import { isLocalStorageAvailable } from './localStorage';

const isProduction = process.env.NODE_ENV === 'production';

export const getLanguageForCKEditor = () => {
    if (!isLocalStorageAvailable()) {
        return navigator?.language?.slice(0, 2) || 'en';
    }

    const localStorageLanguage = window.localStorage?.getItem('i18nextLng');
    return localStorageLanguage?.slice(0, 2) || 'en';
};

export const initLanguageFromMsTeams = (msTeamsLocale) => {
    if (isLocalStorageAvailable()) {
        if (!window.localStorage.i18nextLng) {
            i18n?.changeLanguage(msTeamsLocale);
        }
    }
};

i18n.use(locizePlugin)
    .use(HttpBackend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        load: 'languageOnly',
        debug: !isProduction,
        fallbackLng: 'en',
        saveMissing: !isProduction,
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
        backend: {
            crossDomain: true,
            requestOptions: {
                // used for fetch, can also be a function (payload) => ({ method: 'GET' })
                mode: 'cors',
                credentials: 'same-origin',
                cache: 'default',
            },
            addPath:
                !isProduction &&
                'https://dev-api.perfectwiki.xyz/locales/add/{{lng}}/{{ns}}',
            loadPath: isProduction
                ? 'https://storage.googleapis.com/perfectwiki-meta/i18n/{{lng}}.{{ns}}.json'
                : 'https://dev-api.perfectwiki.xyz/locales/{{lng}}/{{ns}}.json',
        },
        supportedLngs: ['en', 'de', 'da', 'es', 'fr', 'ja', 'nl', 'ru', 'zh'],
        defaultNS: 'general',
    });

export default i18n;
