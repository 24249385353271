import { atom, useAtom } from 'jotai';
import { useCallback } from 'react';
import { useMutation } from 'react-query';
import { updateUserPreferences } from 'services/api';
import { getUserNameFromEmail } from 'services/utils';

import { LicenseNames } from '../types';

const applicationContextState = atom({});
const channelsContextState = atom([]);
const userPreferences = atom({});

export function useApplicationContextState() {
    const [applicationContext, setApplicationContext] = useAtom(
        applicationContextState,
    );
    const [channels, setAccessibleChannels] = useAtom(channelsContextState);
    const [preferences, setUserPreferences] = useAtom(userPreferences);

    const updateUserPreferencesMutation = useMutation(({ tid, payload }) => {
        return updateUserPreferences(tid, payload);
    });

    const setApplicationContextState = useCallback(
        (context) => {
            setApplicationContext(context);
            setAccessibleChannels(context.channels);
            setUserPreferences(context.user?.preferences || {});
        },
        [setApplicationContext, setAccessibleChannels, setUserPreferences],
    );

    const updateUserName = useCallback(
        (userName) => {
            setApplicationContext({
                ...applicationContext,
                user: { ...applicationContext.user, name: userName },
            });
        },
        [setApplicationContext, applicationContext],
    );

    const getApplicationContextState = useCallback(
        () => applicationContext,
        [applicationContext],
    );

    const getLicense = useCallback(() => {
        function getPlanDefinitionByName(planName) {
            if (planName.includes('FREE_TRIAL')) {
                return {
                    displayName: 'Free Trial',
                    knowledgeBaseCountLimit: 10,
                    aiRequestsMonthlyLimit: 1000,
                };
            }

            if (planName.includes('perfect-wiki-pro-subscription')) {
                return {
                    displayName: 'Pro',
                    knowledgeBaseCountLimit: 1,
                    aiRequestsMonthlyLimit: 500,
                };
            }

            if (planName.includes('perfect-wiki-premium-subscription')) {
                return {
                    displayName: 'Premium',
                    knowledgeBaseCountLimit: 3,
                    aiRequestsMonthlyLimit: 2000,
                };
            }

            if (planName.includes('perfect-wiki-ultimate-subscription')) {
                return {
                    displayName: 'Ultimate',
                    knowledgeBaseCountLimit: 30,
                    aiRequestsMonthlyLimit: 4000,
                };
            }

            if (planName.includes('perfect-wiki-enterprise-subscription')) {
                return {
                    displayName: 'Enterprise',
                    knowledgeBaseCountLimit: 100,
                    aiRequestsMonthlyLimit: 4000,
                };
            }

            if (planName.includes('enterprise')) {
                return {
                    displayName: 'Enterprise (Archived Subscription Plan)',
                    knowledgeBaseCountLimit: 100,
                    aiRequestsMonthlyLimit: 4000,
                };
            }

            return {
                displayName: 'Premium (Archived Subscription Plan)',
                knowledgeBaseCountLimit: 30,
                aiRequestsMonthlyLimit: 500,
            };
        }

        const licenseLimits = applicationContext.licenseLimits;

        return {
            ...licenseLimits,
            ...getPlanDefinitionByName(licenseLimits?.planName),
            isFreePlan: licenseLimits?.planName === 'FREE_TRIAL',
            isEnterprisePlan:
                licenseLimits?.planName?.includes('enterprise') ||
                licenseLimits?.planName?.includes('ultimate'),
        };
    }, [applicationContext]);

    const getLicenseName = useCallback(() => {
        const { isFreePlan, isEnterprisePlan } = getLicense();

        if (isFreePlan) {
            return LicenseNames.Free;
        }

        if (isEnterprisePlan) {
            return LicenseNames.Enterprise;
        }

        return LicenseNames.Company;
    }, [getLicense]);

    const getOrganization = useCallback(() => {
        const organization = applicationContext.organization;

        return {
            ...organization,
            isDevOrg:
                organization.id === '89c663c2-211c-4e7c-834c-b945c28cc308' || // pw
                organization.id === '7c1a498c-b70b-4bf2-a174-fb1d709b711b', //lamex-food
        };
    }, [applicationContext]);

    const setOrganization = useCallback(
        (organization) => {
            setApplicationContext({
                ...applicationContext,
                organization: {
                    ...applicationContext.organization,
                    ...organization,
                },
            });
        },
        [setApplicationContext, applicationContext],
    );

    const setLicenseLimits = useCallback(
        (licenseLimits) => {
            setApplicationContext({
                ...applicationContext,
                licenseLimits: {
                    ...applicationContext.licenseLimits,
                    ...licenseLimits,
                },
            });
        },
        [setApplicationContext, applicationContext],
    );

    const getUser = useCallback(() => {
        const user = applicationContext.user;
        const userName = user.name || getUserNameFromEmail(user.id);

        const channelSortOrder = preferences.sortingOptionsByChannelId || [];

        const getChannelSortOrder = (channelId) => {
            if (!channelId) {
                return null;
            }

            const setting = channelSortOrder?.find(
                (ch) => ch.channelId === channelId,
            );

            if (!setting) {
                return null;
            }

            return setting.sortOrder;
        };

        const setChannelSortOrder = async ({ channelId, sortOrder }) => {
            if (!channelId || !sortOrder) {
                return;
            }

            const sortingOptions = preferences.sortingOptionsByChannelId || [];
            const optionsMap = new Map(
                sortingOptions.map((option) => {
                    return [option.channelId, option];
                }),
            );

            optionsMap.set(channelId, { channelId, sortOrder });
            const result = Array.from(optionsMap.values());

            setUserPreferences({
                ...preferences,
                sortingOptionsByChannelId: result,
            });

            await updateUserPreferencesMutation.mutateAsync({
                tid: applicationContext.organization.id,
                payload: { sortingOptionsByChannelId: result },
            });
        };

        return {
            ...user,
            locale:
                user?.locale ||
                window?.navigator?.userLanguage ||
                window?.navigator?.language,
            preferences: preferences,
            isAdmin: user?.role === 'admin',
            isBlocked: user?.role === 'blocked',
            isEditor: user?.role === 'user',
            isReader: user?.role === 'reader',
            email: user?.id,
            userName,
            getChannelSortOrder,
            setChannelSortOrder,
            setUserPreferences,
            updateUserName,
        };
    }, [
        preferences,
        applicationContext,
        updateUserPreferencesMutation,
        setUserPreferences,
        updateUserName,
    ]);

    const getAccessibleChannels = useCallback(
        (includeArchived = false) => {
            if (includeArchived) {
                return channels;
            }

            return channels.filter((ch) => ch.state !== 'deleted');
        },
        [channels],
    );

    const getEnvironment = useCallback(() => {
        const env = applicationContext.environment;

        return {
            environment: env,
            isMsalBrowser: env === 'MSAL_WEB',
            isGoogleBrowser: env === 'GOOGLE_WEB',
        };
    }, [applicationContext]);

    return {
        applicationContext,
        setApplicationContextState,
        getApplicationContextState,
        getEnvironment,
        getUser,
        getOrganization,
        setOrganization,
        getLicense,
        getLicenseName,
        getAccessibleChannels,
        setAccessibleChannels,
        setLicenseLimits,
    };
}
